import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  { path: '/login', name: 'Login', component: () => import('./../views/LoginView.vue') },
  { path: '/new-project', name: 'NewProject', component: () => import('./../views/CreateProjectView.vue') },
  { path: '/project/:id', name: 'DetailProject', component: () => import('./../views/DetailProjectView.vue') },
  { path: '/me', name: 'UserProfil', component: () => import('./../views/UserProfilView.vue') },

  { path: '/:pathMatch(.*)*', component: () => HomeView }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
