
	import Vue from 'vue'

	export default Vue.extend({
		name: 'Home',

		data() {
			return {
				page: 1,
				search: '',
				wantInprogress: false
			}
		},

		computed: {
			projects(): Array<any> {
				const projects = this.$store.getters['listProjects/projects']	
				
				
				return projects.filter((project: any) => {
					if(this.wantInprogress == false) {
						return true
					}
					if(project.securityStepDone == false || project.deploymentStepDone == false) {
						return true
					}
					return false
				})
			},

			numberOfPages(): number {
				const numberOfProject = (this as any).projects.length
				return Math.ceil(numberOfProject / 10)
	  		}
		},

		methods: {
			nextPage () {
				if (this.page + 1 <= this.numberOfPages) this.page += 1
			},
			formerPage () {
				if (this.page - 1 >= 1) this.page -= 1
			},
			goto(projectId: string) {
				this.$router.push('/project/' + projectId)
			}
		}
	})
