import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAzr0iNYF2PVLcHITIwwKGCg9c6gWqiqqU",
    authDomain: "teams4it-9110d.firebaseapp.com",
    projectId: "teams4it-9110d",
    storageBucket: "teams4it-9110d.appspot.com",
    messagingSenderId: "766819513502",
    appId: "1:766819513502:web:d892ba4944b1adc2def78f"
}

const app = initializeApp(firebaseConfig)

export const firebaseApp = app
export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)

export const uploadFile = async function(file: File, projectId: string) {
    const path = projectId + '/' + Date.now() + '_' + file.name
    const storageRef = ref(storage, path)

    return await uploadBytes(storageRef, file)
}

export const downloadFile = async function(fullPath: string, name: string) {
    const url = await getDownloadURL(ref(storage, fullPath))
    if(url) {
        const link = document.createElement('a')
        link.download = name // a check
        link.target = '_blank'
        link.href = url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        return true
    }
    return false
}

export const deleteFile = async function(fullPath: string) {
    const reference = ref(storage, fullPath)

    return new Promise((resolve, reject) => {
        deleteObject(reference)
            .then(() => resolve(true))
            .catch((error) => reject(error))
    })
}