import { db } from "./../firebase";
import { query, collection, where, onSnapshot } from 'firebase/firestore'
import { Module } from "vuex";

const ListProjectsModule: Module<any, any> = {

    namespaced: true,

    state: () => ({
        projects: {},
        listeners: []
    }),

    mutations: {
        setProject(state, project) {
            state.projects = {
                ...state.projects,
                [project.id]: project
            }
        },

        removeProject(state, id) {
            delete state.projects[id]
        },

        addListener(state, listener) {
            state.listeners.push(listener)
        },

        stop(state) {
            state.projects = {}
            state.listeners = []
        }
    },

    actions: {

        async loadUserProjects({ commit, dispatch, rootGetters }) {
            await dispatch('stop')
            const q = query(collection(db, 'projects'), where("users", "array-contains", rootGetters['auth/user'].uid))
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    if(change.type !== 'removed') {
                        commit('setProject', {
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    } else {
                        commit('removeProject', change.doc.id)
                    }
                    
                })
            })
            commit('addListener', unsubscribe)
        },

        async stop({ state, commit }) {
            for(const listener of state.listeners) {
                listener()
            }
            commit('stop')
        }

    },

    getters: {
        projects: state => {
            const projects = []
            for(const projectId of Object.keys(state.projects)) {
                projects.push({
                    ...state.projects[projectId],
                    id: projectId
                })
            }
            return projects
        },
        project: state => (id: string) => state.projects[id],
    }

}

export default ListProjectsModule