import { db } from "@/firebase";
import { collection, CollectionReference, doc, DocumentData, getDoc, onSnapshot, Query, query, where } from "firebase/firestore";
import { Module } from "vuex";


const UsersModule: Module<any, any> = {

    namespaced: true,

    state: () => ({
        users: {},
        listener: null,
    }),

    mutations: {
        setUsers(state, users) {
            state.users = users
        },
        setListener(state, listener) {
            state.listener = listener
        } 
    },

    actions: {

        async load({ commit, rootGetters }) {
            const userDoc = await getDoc(doc(db, 'users', rootGetters['auth/user'].uid))

            if(userDoc.exists()) {
                const enterpriseName = userDoc.data().enterprise
                let c: CollectionReference<DocumentData> | Query<DocumentData> = collection(db, 'users');

                if(enterpriseName != 'wats') {
                    c = query(c, where('enterprise', 'in', [enterpriseName, 'wats']))
                }
                
                const listener = onSnapshot(c, (querySnapshot) => {
                    const users: any = {}
                    for(const user of querySnapshot.docs) {
                        users[ user.id ] = user.data()
                    }
                    commit('setUsers', users)
                })
                commit('setListener', listener)
            }
        },

        remove({ commit, state }) {
            if(state.listener !== null) {
                state.listener()
            }
            commit('setUsers', {})
            commit('setListener', null)
        }

    },

    getters: {
        users: state => state.users,
        user: state => (userId: string) => state.users[userId]
    }

}

export default UsersModule