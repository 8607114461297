import { db } from "@/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Module } from "vuex";


const ProjectNotesModule: Module<any, any> = {

    namespaced: true,

    state: () => ({

        id: null,
        notes: []

    }),

    mutations: {
        reset(state) {
            state.id = null
            state.notes = []
        },

        setId(state, id) {
            state.id = id
        },

        setNotes(state, notes) {
            state.notes = notes
        },

        addNote(state, note) {
            state.notes.push(note)
        } 
    },

    actions: {

        async reset({ commit }) {
            commit('reset')
        },

        async load({ commit, rootGetters }, projectId: string) {
            commit('reset')
            const project = await rootGetters['listProjects/project'](projectId)
            if(project) {
                commit('setId', projectId)
                commit('setNotes', project.notes ?? [])
            }
        },

        async addMessage({ commit, rootGetters, dispatch }, message) {
            const msg = {
                user: rootGetters['auth/user'].uid,
                message,
                timestamp: Date.now()
            }
            commit('addNote', msg)
            await dispatch('save')
        },

        async save({ state }) {
            if(state.id !== null) {
                const datas = {
                    notes: state.notes
                }

                try {
                    return await updateDoc(doc(db, 'projects', state.id), datas)
                } catch(e) {
                    console.log(e)
                    return false
                }
            }
            return false
        }

    },

    getters: {

        notes: state => {
            const notes = [...state.notes]
            notes.sort((a: any, b: any) => {
                if(a.timestamp == b.timestamp) {
                    return 0
                }
                return a.timestamp < b.timestamp ? 1 : -1
            })
            return notes
        }

    }

}

export default ProjectNotesModule