import { Module } from "vuex";
import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import { auth } from "@/firebase";
import router from "@/router";

const AuthModule: Module<any, any> = {
    namespaced: true,

    state: () => ({
        user: {
            loggedIn: false,
            data: null
        }
    }),

    mutations: {
        SET_LOGGED_IN(state, isLogged) {
            state.user.loggedIn = isLogged
        },
        SET_USER(state, data) {
            state.user.data = data
        }
    },

    actions: {
        async logIn(context, { email, password }) {
            const response = await signInWithEmailAndPassword(auth, email, password)
            if(response) {
                context.commit('SET_USER', response.user)
                context.dispatch('listProjects/loadUserProjects', null, { root: true })
                context.dispatch('users/load', null, { root: true })
                router.push('/')
            } else {
                throw new Error('login failed')
            }
        },
        async logOut(context){
            await signOut(auth)
            context.commit('SET_USER', null)
            context.commit('SET_LOGGED_IN', false)
            await context.dispatch('users/remove', null, { root: true })
            await context.dispatch('listProjects/stop', null, { root: true })
            await context.dispatch('projectForm/reset', null, { root: true })
            await context.dispatch('projectNotes/reset', null, { root: true })
        },
        async fetchUser(context, user) {
            context.commit("SET_LOGGED_IN", user !== null);
            if (user) {
                context.commit("SET_USER", {
                    uid: user.uid,
                    displayName: user.displayName,
                    email: user.email
                })
                context.dispatch('listProjects/loadUserProjects', null, { root: true })
                context.dispatch('users/load', null, { root: true })
            } else {
                context.commit("SET_USER", null);
            }
        }
    },

    getters: {
        user: state => state.user.data,
        isLogged: state => state.user.loggedIn
    }
}


export default AuthModule;