import { db } from "@/firebase";
import { addDoc, collection } from "firebase/firestore";
import { Module } from "vuex";



const MailModule: Module<any, any> = {

    namespaced: true,

    state:() => ({}),

    mutations: {},

    actions: {

        async send({}, { to, subject, html }) {

            const datas = {
                to,
                message: {
                    subject,
                    html
                }
            }

            try {
                return await addDoc(collection(db, 'mail'), datas)
            } catch(e) {
                console.log(e)
                return false
            }
        }

    },

    getters: {}

}

export default MailModule