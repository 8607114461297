import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './AuthModule'
import ListProjectsModule from './ListProjectsModule'
import MailModule from './MailModule'
import ProjectFormModule from './ProjectFormModule'
import ProjectNotesModule from './ProjectNotesModule'
import UsersModule from './UsersModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: AuthModule,
    listProjects: ListProjectsModule,
    projectForm: ProjectFormModule,
    projectNotes: ProjectNotesModule,
    users: UsersModule,
    mail: MailModule
  }
})
