import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from './firebase'
import { User } from 'firebase/auth';

Vue.config.productionTip = false
Vue.use(require('vue-moment'))

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
})

let mounted: any = null

auth.onAuthStateChanged((user: User | null) => {
  if(!user) {
    router.push('/login')
  } else {
    store.dispatch('auth/fetchUser', user)
  }

  if(mounted === null) {
    mounted = app.$mount('#app')
  }
})


