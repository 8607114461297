import { db, deleteFile, uploadFile } from "@/firebase";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { Module } from "vuex";

const itsmList = ['ServiceNow', 'EasyVista', 'MicroFocus']

const ProjectFormModule: Module<any, any> = {

    namespaced: true,

    state: () => ({
        id: null,
        parameters: {
            tenantIdClient: '',
            nbLicences: 0,
            genesysDeploymentId: '',
            itsm: '',
            beginRunDate: '',
        },
        clientName: '',
        securityStepDone: false,
        deploymentStepDone: false,
        users: [],

        securityDocs: [],

        itsmList: itsmList
    }),

    mutations: {

        reset(state) {
            state.id = null

            state.parameters =  {
                tenantIdClient: '',
                nbLicences: 0,
                genesysDeploymentId: '',
                itsm: '',
                beginRunDate: '',
            }
            state.clientName = ''
            state.securityStepDone = false
            state.deploymentStepDone = false
            state.users = []
            state.securityDocs = []
        },

        setId(state, id) {
            state.id = id
        },

        setItsm(state, itsm) {
            if(itsmList.includes(itsm)) {
                state.parameters.itsm = itsm
            }
        },

        setTenantId(state, tenantId) {
            state.parameters.tenantIdClient = tenantId
        },

        setNbLicences(state, nbLicences) {
            state.parameters.nbLicences = nbLicences
        },

        setGenesysDeploymentId(state, deploymentId) {
            state.parameters.genesysDeploymentId = deploymentId
        },

        setBeginRunDate(state, timestamp) {
            state.parameters.beginRunDate = timestamp
        },

        setClientName(state, clientName) {
            state.clientName = clientName
        },

        setUsers(state, users) {
            if(users.length > 0) {
                state.users = users
            }
        },

        setDeploymentStepDone(state, done) {
            state.deploymentStepDone = done
        },

        setSecurityStepDone(state, done) {
            state.securityStepDone = done
        },

        setSecurityDocs(state, docs) {
            state.securityDocs = docs
        }

    },

    actions: {
        async load({ commit, rootGetters }, id) {
            commit('reset')
            const project = await rootGetters['listProjects/project'](id)
            if(project) {
                commit('setId', project.id)
                commit('setItsm', project.parameters.itsm)
                commit('setTenantId', project.parameters.tenantIdClient)
                commit('setNbLicences', project.parameters.nbLicences)
                commit('setGenesysDeploymentId', project.parameters.genesysDeploymentId)
                commit('setBeginRunDate', project.parameters.beginRunDate)
                commit('setClientName', project.clientName)
                commit('setUsers', project.users)
                commit('setDeploymentStepDone', project.deploymentStepDone)
                commit('setSecurityStepDone', project.securityStepDone)
                commit('setSecurityDocs', project.securityDocs)
            }
        },

        reset({ commit }) {
            commit('reset')
        },

        async uploadFile({ state, commit, dispatch, rootGetters }, file) {
            const fileUploaded = await uploadFile(file, state.id)
            if(fileUploaded) {
                const docs = state.securityDocs
                docs.push({
                    metadata: {
                        size: fileUploaded.metadata.size,
                        updated: fileUploaded.metadata.updated,
                        timeCreated: fileUploaded.metadata.timeCreated,
                        name: fileUploaded.metadata.name,
                        fullPath: fileUploaded.metadata.fullPath,
                        contentType: fileUploaded.metadata.contentType
                    },
                    timestamp: Date.now(),
                    user: rootGetters['auth/user'].uid
                })
                commit('setSecurityDocs', docs)
                await dispatch('save')
            }

            return fileUploaded
        },

        async updateFile({ state, commit, dispatch, rootGetters }, { oldFullPath, file }) {
            const fileUploaded = await uploadFile(file, state.id)
            if(fileUploaded) {
                const docs = state.securityDocs.filter((doc: any) => doc.metadata.fullPath != oldFullPath)
                docs.push({
                    metadata: {
                        size: fileUploaded.metadata.size,
                        updated: fileUploaded.metadata.updated,
                        timeCreated: fileUploaded.metadata.timeCreated,
                        name: fileUploaded.metadata.name,
                        fullPath: fileUploaded.metadata.fullPath,
                        contentType: fileUploaded.metadata.contentType
                    },
                    timestamp: Date.now(),
                    user: rootGetters['auth/user'].uid
                })

                commit('setSecurityDocs', docs)
                await dispatch('save')
            }

            return fileUploaded
        },

        async deleteFile({ state, commit, dispatch }, fullPath) {
            const fileDeleted = await deleteFile(fullPath)
            if(fileDeleted) {
                const docs = state.securityDocs.filter((doc: any) => doc.metadata.fullPath != fullPath)
                commit('setSecurityDocs', docs)
                await dispatch('save')
            }

            return fileDeleted
        },

        async save({ state, commit, rootGetters }) {
            if(state.id === null) {
                if(!state.users.includes(rootGetters['auth/user'].uid)) {
                    commit('setUsers', [ ...state.users, rootGetters['auth/user'].uid ])
                }

                const datas = {
                    users: state.users,
                    parameters: state.parameters,
                    clientName: state.clientName,
                    securityStepDone: false,
                    deploymentStepDone: false,
                    securityDocs: []
                }

                try {
                    return await addDoc(collection(db, 'projects'), datas)
                } catch(e) {
                    console.log(e)
                    return false
                }
            } else {
                const datas = {
                    users: state.users,
                    parameters: state.parameters,
                    clientName: state.clientName,
                    securityStepDone: state.securityStepDone,
                    deploymentStepDone: state.deploymentStepDone,
                    securityDocs: state.securityDocs
                }

                try {
                    return await updateDoc(doc(db, 'projects', state.id), datas)
                } catch(e) {
                    console.log(e)
                    return false
                }
            }
        }
    },

    getters: {

        itsmList: state => state.itsmList,

        id: state => state.id,
        clientName: state => state.clientName,
        users: state => state.users,
        beginRunDate: state => state.parameters.beginRunDate,
        itsm: state => state.parameters.itsm,
        nbLicences: state => state.parameters.nbLicences,
        tenantIdClient: state => state.parameters.tenantIdClient,
        genesysDeploymentId: state => state.parameters.genesysDeploymentId,
        securityStepDone: state => state.securityStepDone,
        deploymentStepDone: state => state.deploymentStepDone,
        securityDocs: state => state.securityDocs,

    }

}

export default ProjectFormModule